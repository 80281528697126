// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    domain: {
        id: 0,
        url: '',
        language: '',
        translate_urls: true,
        dynamic_translations: false,
        url_separator: '-',
        allowed_languages: ['en', 'zh-cn', 'es', 'fr', 'de', 'ru'],
        structure: null,
        account_id: null,
        default_rules: true, // used only while adding new domain
        dns_checked: null,
        dns_languages_checked: {},
        dns_txt_checked: false,
        domain_valid: false,
        platform: null,
        language_settings: undefined,
        entri_opened_at: null,
        entri_used_at: null,
        notice: null,
        translate_not_found_page: true,
        translate_error_page: false,
    },
    saving: false,
    saved: false,
    deleted: false,
    dns_checking: false,
    dns_checked_already: false,
    error: null,
    loading: false,
    force_saving: false,
};

type State = { type?: string };

const Domain = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'DOMAIN_LOADED':
            if (action.payload === null) {
                // New domain, reset the data
                return {...INITIAL_STATE};
            }

            action.payload.translate_urls = action.payload.translate_urls === 1;
            action.payload.dynamic_translations = action.payload.dynamic_translations === 1;
            action.payload.translate_not_found_page = action.payload.translate_not_found_page === 1;
            action.payload.translate_error_page = action.payload.translate_error_page === 1;
            if (!action.payload.allowed_languages.length) {
                action.payload.allowed_languages = INITIAL_STATE.domain.allowed_languages;
            }

            return update(state, {domain: {$merge: action.payload}});

        case 'DOMAIN_CHANGE_INPUT':
            const result = /(.*)\[([0-9]+)\](.*)?/.exec(action.payload.name);
            if (result) {
                const new_state = update(state, {
                    domain: {
                        [result[1]]: {
                            [parseInt(result[2])]: {
                                $set: action.payload.value
                            }
                        }
                    }
                });
                return {...new_state};
            }
            return update(state, {domain: {[action.payload.name]: {$set: action.payload.value}}});

        case 'DOMAIN_SAVE':
            return {...state, saving: true, error: null};

        case 'DOMAIN_SAVED':
            return update(state, {saved: {$set: true}, force_saving: {$set: false}, error: {$set: null}, domain: {id: {$set: action.payload}}});

        case 'DOMAIN_DELETED':
            return {...state, deleted: true};

        case 'DOMAIN_CLOSED':
            return {...INITIAL_STATE};

        case 'DOMAIN_REQUEST_FAILED':
            return {...state, saving: false, deleting: false, error: action.payload, force_saving: true};

        case 'DOMAIN_CHECK_DNS':
            return {...state, dns_checking: true};

        case 'DOMAIN_CHECKED_DNS':
            return update(state, {domain: {dns_languages_checked: {$set: action.payload.dns_languages_checked}, dns_txt_checked: {$set: action.payload.dns_txt_checked}}, dns_checking: {$set: false}, dns_checked_already: {$set: true}});

        case 'DOMAIN_LANGUAGE_SETTINGS_LOAD':
            return {...state, loading: true};

        case 'DOMAIN_LANGUAGE_SETTINGS_LOADED':
            return update(state, {domain: {language_settings: {$set: action.payload}}, loading: {$set: false}});

        case 'DOMAIN_LANGUAGE_SETTINGS_SAVE':
            return {...state, saving: true, error: null};

        case 'DOMAIN_LANGUAGE_SETTINGS_SAVED':
            return update(state, {domain: {language_settings: {$set: action.payload}}, saving: {$set: false}});

        case 'DOMAIN_LANGUAGE_SETTINGS_REQUEST_FAILED':
            return {...state, saving: false};

        case 'DOMAIN_LANGUAGE_SETTINGS_CLEAR_DATA':
            return update(state, {domain: {language_settings: {$set: undefined}}});

        case 'DOMAIN_SET_FORCE_SAVING':
            return update(state, {force_saving: {$set: action.payload}});

        default:
            return {...state};
    }
};

export default Domain;
