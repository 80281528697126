// @flow

import update from 'immutability-helper';
import Constants from '../../helpers/constants';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    rule: {
        id: 0,
        name: 'Rule ' + new Date().toLocaleString(),
        type: Constants.RULE_TYPE_TEXT_IGNORE,
        params: {
            text_ignore: [
                {
                    text_ignore: '',
                    text_ignore_type: 'wildcard', // this is also set at line 71
                    ignore_case: true
                }
            ],
            text_replace: [
                {
                    text_replace: '',
                    text_replace_type: 'wildcard', // this is also set at line 80
                    text_replacement: '',
                    ignore_case: true,
                    keep_case: true,
                    source_type: 'original',
                }
            ],
            apply_to_elements: 'all',
            element_selectors: [''],
            apply_to_urls: 'all',
            urls: [
                {
                    url: '',
                    source: 'original',
                    source_type: 'wildcard',
                }
            ],
            apply_to_languages: 'all',
            apply_on_content: 'all',
            languages: [],
            apply_on_url: true,
            multilingual_url: true
        }
    },
    saving: false,
    saved: false
};

type State = { type?: string };

const Rule = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'RULE_LOADED':
            let rule = INITIAL_STATE.rule;

            // Case new rule
            if (action.payload === null) {
                return {...state, rule: rule};
            }

            // Merge loaded item with default state
            Object.keys(action.payload).forEach((key)=>{
                if(key==='params') {
                    // proceed params sub keys
                    Object.keys(action.payload[key]).forEach((key) => {
                        // fix missing keys
                        const ruleItems = Array.isArray(action.payload.params[key])
                            ? action.payload.params[key].map((item) => {
                                switch (key) {
                                    case 'text_ignore':
                                        return {
                                            ...item,
                                            text_ignore_type: item.text_ignore_type || 'wildcard'
                                        };

                                    case 'text_replace':
                                        return {
                                            ...item,
                                            text_replace_type: item.text_replace_type || 'wildcard'
                                        };

                                    default:
                                        return item;
                                }
                            })
                            : action.payload.params[key];

                        // First level key
                        rule = update(rule, {params: {[key]: {$set: ruleItems}}})
                    });
                } else {
                    // First level key
                    rule = update(rule, {[key]: {$set: action.payload[key]}})
                }
            });

            // Set button depending on loaded item
            if (action.payload.params.element_selectors !== undefined) {
                rule.params.apply_to_elements = 'some';
            }

            if (action.payload.params.urls !== undefined) {
                rule.params.apply_to_urls = 'some';
            }

            if (action.payload.params.languages !== undefined) {
                rule.params.apply_to_languages = 'some';
            }
            return {...state, rule: rule};

        case 'RULE_CHANGE_TYPE':
            return {...state, rule: {type: action.payload,}};

        case 'RULE_CHANGE_INPUT':
            const result = /(.*)\[([0-9]+)\](.*)?/.exec(action.payload.name);
            if (result && result[3] === undefined) {
                let new_state;
                if (action.payload.type==='params') {
                    new_state = update(state, {rule: { params: {[result[1]]: {[parseInt(result[2])]: {$set: action.payload.value}}}}});
                } else {
                    new_state = update(state, {rule: {[result[1]]: {[parseInt(result[2])]: {$set: action.payload.value}}}});
                }
                return {...new_state};
            } else if (result) {
                let new_state;
                if (action.payload.type==='params') {
                    new_state = update(state, {rule: {params: {[result[1]]: {[parseInt(result[2])]: {[result[3]]: {$set: action.payload.value}}}}}});
                } else {
                    new_state = update(state, {rule: {[result[1]]: {[parseInt(result[2])]: {[result[3]]: {$set: action.payload.value}}}}});
                }
                return {...new_state};
            }
            if (action.payload.type==='params') {
                return update(state, {rule: {params: {[action.payload.name]: {$set: action.payload.value}}}});
            } else {
                return update(state, {rule: {[action.payload.name]: {$set: action.payload.value}}});
            }

        case 'RULE_SUB_ADD':
            switch (action.payload.type) {
                case 'text ignore':
                    let text_ignore = [...state.rule.params.text_ignore];
                    text_ignore.push({...INITIAL_STATE.rule.params.text_ignore[0]});
                    return update(state, {rule: {params: {text_ignore: {$set: text_ignore}}}});
                case 'text replace':
                    let text_replace = [...state.rule.params.text_replace];
                    text_replace.push({...INITIAL_STATE.rule.params.text_replace[0]});
                    return update(state, {rule: {params: {text_replace: {$set: text_replace}}}});
                case 'element selector':
                    let element_selectors = [...state.rule.params.element_selectors];
                    element_selectors.push(INITIAL_STATE.rule.params.element_selectors[0]);
                    return update(state, {rule: {params: {element_selectors: {$set: element_selectors}}}});
                case 'urls':
                    let urls = [...state.rule.params.urls];
                    urls.push(INITIAL_STATE.rule.params.urls[0]);
                    return update(state, {rule: {params: {urls: {$set: urls}}}});
                default:
                    return {...state};
            }

        case 'RULE_SUB_DELETE':
            switch (action.payload.type) {
                case 'text ignore':
                    let text_ignore = [...state.rule.params.text_ignore];
                    text_ignore.splice(action.payload.index, 1);
                    return update(state, {rule: {params: {text_ignore: {$set: text_ignore}}}});
                case 'text replace':
                    let text_replace = [...state.rule.params.text_replace];
                    text_replace.splice(action.payload.index, 1);
                    return update(state, {rule: {params: {text_replace: {$set: text_replace}}}});
                case 'element selector':
                    let element_selectors = [...state.rule.params.element_selectors];
                    element_selectors.splice(action.payload.index, 1);
                    return update(state, {rule: {params: {element_selectors: {$set: element_selectors}}}});
                case 'urls':
                    let urls = [...state.rule.params.urls];
                    urls.splice(action.payload.index, 1);
                    return update(state, {rule: {params: {urls: {$set: urls}}}});
                default:
                    return {...state};
            }

        case 'RULE_SAVE':
            return {...state, saved: false, saving: true};

        case 'RULE_SAVED':
            return {...state, saved: false, saving: false};

        case 'RULE_SAVED_AND_QUIT':
            return {...INITIAL_STATE, saved: true, saving: false};

        case 'RULE_CLOSED':
            return {...INITIAL_STATE};

        case 'RULE_REQUEST_FAILED':
            return {...state, saving: false, deleting: false};

        default:
            return {...state};
    }
};

export default Rule;
